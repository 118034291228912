import React from 'react';
import styles from './SingleTitleSection.module.scss';

const SingleTitleSection: React.FC = () => {
  return (
    <section className={styles.section}>
      <h2>A world of stone and tile for inside and out</h2>
    </section>
  );
};

export default SingleTitleSection;
