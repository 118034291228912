import React, { FC, useEffect, useState } from 'react';
import styles from './HeroGrid.module.scss';
import classnames from 'classnames';
import { HeroSlide } from '../../projects-intro/ProjectsIntro';

interface Props {
  data: HeroSlide[];
}

const HeroGrid: FC<Props> = ({ data }) => {
  const [viewportWidth, setViewportWidth] = useState<number | null>(null);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setViewportWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.hidden]: viewportWidth && viewportWidth <= 500,
      })}
    >
      {data.slice(0, 4).map((item) => (
        <div key={item.id} className={styles.itemLink}>
          {item.link ? (
            <a href={item.link} className={styles.itemWrap}>
              <img src={item.image?.url} alt={item.text} className={styles.itemImage} />
            </a>
          ) : (
            <div className={styles.itemWrap}>
              <img src={item.image?.url} alt={item.text} className={styles.itemImage} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default HeroGrid;
