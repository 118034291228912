import React, { useEffect, useState } from 'react';
import styles from './FollowUs.module.scss';
import Carousel from 'nuka-carousel';
import arrowLeft from '../../../images/black-arrow-left.svg';
import arrowRight from '../../../images/black-arrow-right.svg';
import useViewport from '../../../hooks/useViewport';
import classnames from 'classnames';
import './FollowUsSlider.scss';
import axios from 'axios';

interface InstagramMedia {
  media_type: string;
  media_url: string;
  permalink: string;
}

interface Props {
  token: string;
}

const FollowUs: React.FC<Props> = ({ token }: Props) => {
  const { isTablet } = useViewport();

  const [images, setImages] = useState([] as InstagramMedia[]);

  useEffect(() => {
    const load = async () => {
      let mediaFound: InstagramMedia[] = [];

      let url = `https://graph.instagram.com/me/media?fields=media_type,media_url,permalink&access_token=${token}`;
      let pageNumber = 0;

      while (mediaFound.length < 10 && pageNumber < 10) {
        const res = await axios.get(url);
        const media =
          res.data?.data?.filter((p: InstagramMedia) =>
            ['IMAGE', 'CAROUSEL_ALBUM'].includes(p.media_type),
          ) ?? [];

        mediaFound = mediaFound.concat(media);

        setImages(mediaFound);
        url = res.data.paging.next;
        if (!url) {
          // finish cycle when no next page
          pageNumber = 10;
        } else {
          pageNumber++;
        }
      }
    };
    try {
      load();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className={classnames(styles.FollowUs, 'follow-us-slider-container')}>
      <h2 className={styles.head}>FOLLOW US ON INSTAGRAM</h2>
      <Carousel
        slidesToShow={isTablet ? 2 : 4}
        slideIndex={0}
        wrapAround={true}
        renderBottomCenterControls={() => <></>}
        renderCenterLeftControls={({ previousSlide }) => (
          <div className={styles.controlBtn + ' ' + styles.btnLeft} onClick={previousSlide}>
            <img src={arrowLeft} alt="arrow left" />
          </div>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <div className={styles.controlBtn + ' ' + styles.btnRight} onClick={nextSlide}>
            <img src={arrowRight} alt="arrow right" />
          </div>
        )}
      >
        {images.map((s, i) => (
          <div className={styles.slide} key={i}>
            <a href={s.permalink} target="_blank" rel="noreferrer">
              <img src={s.media_url} className={styles.slide} alt="image" />
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default FollowUs;
