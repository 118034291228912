import React, { FC } from 'react';
import { HeroSlide } from '../projects-intro/ProjectsIntro';
import HeroGrid from './HeroGrid/HeroGrid';
import HeroSlider from './HeroSlider/HeroSlider';

interface Props {
  data: HeroSlide[];
}

const HeroSection: FC<Props> = ({ data }) => {
  const sortedData = data.sort((a, b) => {
    if (a.sortIndex === undefined && b.sortIndex !== undefined) {
      return 1;
    }
    if (a.sortIndex !== undefined && b.sortIndex === undefined) {
      return -1;
    }
    if (a.sortIndex && b.sortIndex) return a.sortIndex - b.sortIndex;
    else return 1;
  });

  return (
    <>
      <HeroSlider slides={sortedData} />
      <HeroGrid data={sortedData} />
    </>
  );
};

export default HeroSection;
