import React from 'react';

import ArrowButton from '../../shared/arrow-button/ArrowButton';
import styles from './OurStory.module.scss';
import { Link } from 'gatsby';

interface Props {
  imgSrc: string;
  title: string;
  text: string;
  mimeType: string;
}

const OurStory: React.FC<Props> = ({ imgSrc, text, title, mimeType }: Props) => {
  return (
    <section className={styles.post}>
      <div className={styles.borderedContainer}>
        <h3 className={styles.header}>How we procure our slabs</h3>
        {mimeType === 'video/mp4' ? (
          <video className={styles.video} src={imgSrc} autoPlay loop muted playsInline />
        ) : (
          <img className={styles.image} src={imgSrc} alt="story image" />
        )}
        <div className={styles.filledContainer}>
          <h3 className={styles.title}>{title}</h3>
        </div>
        <div className={styles.contentBox}>
          <p className={styles.content}>{text}</p>
          <Link to="/slab" className={styles.linkTitle}>
            <ArrowButton
              isWhite={false}
              name="EXPLORE OUR SLAB COLLECTION"
              imgLeftMargin={'3rem'}
              nameClass={styles.ourStoryLink}
              className={styles.arrowLink}
            />
          </Link>
        </div>
        {/* <Link to="/about" className={styles.linkTitle}>
          <ArrowButton isWhite={false} name="LEARN MORE ABOUT US" imgLeftMargin={'3rem'} />
        </Link> */}
      </div>
    </section>
  );
};

export default OurStory;
