import React from 'react';
import styles from './Reviews.module.scss';
import Carousel from 'nuka-carousel';
import arrowLeft from '../../../images/white-arrow-left.svg';
import arrowRight from '../../../images/white-arrow-right.svg';

export interface Review {
  id: number;
  review: string;
  name: string;
  position: string;
  firm: string;
}

interface Props {
  data: Review[];
}

const Reviews: React.FC<Props> = ({ data }: Props) => (
  <div className={styles.Reviews}>
    <h2 className={styles.head}>WHAT OUR CLIENTS SAY ABOUT US</h2>
    <Carousel
      wrapAround={true}
      slideIndex={1}
      renderBottomCenterControls={() => <></>}
      renderCenterLeftControls={({ previousSlide }) => (
        <div className={styles.controlBtn + ' ' + styles.btnLeft} onClick={previousSlide}>
          <img src={arrowLeft} alt="arrow left" />
        </div>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <div className={styles.controlBtn + ' ' + styles.btnRight} onClick={nextSlide}>
          <img src={arrowRight} alt="arrow right" />
        </div>
      )}
    >
      {data.map((s) => (
        <div className={styles.slide} key={`review_${s.id}`}>
          <div className={styles.text} dangerouslySetInnerHTML={{ __html: s.review }}></div>
          <div className={styles.info}>
            <div className={styles.client + ' ' + styles.item}>
              <p>CLIENT</p>
              <p>{s.name + (s.position ? ` | ${s.position}` : '')}</p>
            </div>
            <div className={styles.company + ' ' + styles.item}>
              <p>FIRM</p>
              <p>{s.firm}</p>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  </div>
);

export default Reviews;
