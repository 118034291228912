import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import TileCard from '../tile-card/TileCard';
import styles from './TilesSection.module.scss';

interface TileCollection {
  name: string;
  id: number;
  image: {
    url: string;
  };
  tile_collection_page: {
    slug: string;
  };
}

const TilesSection: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        tileCollections {
          id
          name
          image {
            url
          }
          tile_collection_page {
            slug
          }
        }
      }
    }
  `);

  const slugRate: { [key: string]: number } = {
    field: 1,
    decorative: 2,
    outdoor: 3,
  };

  const collections: TileCollection[] = data.strapi.tileCollections;
  collections.sort(
    (a, b) => slugRate[a.tile_collection_page.slug] - slugRate[b.tile_collection_page.slug],
  );

  return (
    <section className={styles.section}>
      <h2>OUR tile COLLECTIONS</h2>
      <div className={styles.list}>
        {collections.map((node, i: number) => (
          <TileCard
            totalTiles={collections.length}
            currentTile={i + 1}
            route={'tile/' + node.tile_collection_page.slug}
            imgSrc={node.image?.url}
            title={node.name}
            key={node.id}
          ></TileCard>
        ))}
      </div>
    </section>
  );
};

export default TilesSection;
