import React, { FC, useEffect, useState } from 'react';
import styles from './HeroSlider.module.scss';
import { HeroSlide } from '../../projects-intro/ProjectsIntro';
import { Navigation } from 'swiper';
import classnames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Props {
  slides: HeroSlide[];
}

const HeroSlider: FC<Props> = ({ slides }) => {
  const [viewportWidth, setViewportWidth] = useState<number | null>(null);
  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setViewportWidth(window.innerWidth);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={classnames(styles.sliderWrapper, {
        [styles.hidden]: viewportWidth && !(viewportWidth <= 500),
      })}
    >
      <Swiper
        modules={[Navigation]}
        spaceBetween={10}
        slidesPerView={2}
        navigation
        className={styles.slider}
      >
        {slides?.slice(0, 4)?.map((slide) => (
          <SwiperSlide key={slide.id} className={styles.slide}>
            {slide.link ? (
              <a href={slide.link} className={styles.slideWrap}>
                <img src={slide.image?.url} alt={slide.text} className={styles.slideImage} />
              </a>
            ) : (
              <div className={styles.slideWrap}>
                <img src={slide.image?.url} alt={slide.text} className={styles.slideImage} />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
