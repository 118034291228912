import { graphql } from 'gatsby';
import React from 'react';
import OurStory from '../components/home/our-story/OurStory';
import { HomeSlide } from '../components/home/projects-intro/ProjectsIntro';
import Reviews, { Review } from '../components/home/reviews/Reviews';
import { HomeSlab } from '../components/home/slab-collections/SlabCollection';
import TilesSection from '../components/home/tiles-section/TilesSection';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import FollowUs from '../components/shared/follow-us/FollowUs';
import styles from './home.module.scss';
import VideoSection from '../components/home/youtube-section/youtubeSection';
import SingleTitleSection from '../components/home/single-title-section/SingleTitleSection';
import HeroSection from '../components/home/hero-section/HeroSection';

export const query = graphql`
  query {
    strapi {
      homeHeroGrids {
        id
        background_color
        sortIndex
        text
        link
        image {
          url
        }
      }
      homePage {
        da_vinci_marble_tale_media {
          url
          alternativeText
        }
        youTube_text
        text
        title
        instagram_token
        our_story_media {
          url
          mime
        }
      }
      slabs(where: { homepage_slide_index_ne: null }, sort: "homepage_slide_index:asc") {
        id
        material {
          id
          name
        }
        finish {
          id
          name
        }
        preview {
          url
        }
        name
        size
      }
      homeSlides(sort: "sortIndex:asc") {
        open_link_in_new_tab
        id
        name
        cta
        description
        url
        image {
          url
        }
        mobile_image {
          url
        }
      }
      reviews {
        id
        review
        name
        position
        firm
      }
    }
  }
`;

const IndexPage: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  const token = data.homePage.instagram_token.trim();
  const mimeType = data.homePage.our_story_media.mime;
  const storyMediaUrl = data.homePage.our_story_media.url;
  // const youTube_link = data.homePage.youTube_link;
  const { homeHeroGrids } = data;
  const youTube_text = data.homePage.youTube_text; // test
  const taleVideoUrl = data.homePage.da_vinci_marble_tale_media.url;
  const videoAltTextField = data.homePage.da_vinci_marble_tale_media.alternativeText;

  return (
    <Layout lightNavbar={true}>
      <SEO title="Home" />
      <div>
        <HeroSection data={homeHeroGrids} />
        {/* {isMobile ? (
          <HeroSlider slides={homeSlides} />
        ) : (
          <ProjectsIntro data={data.homeHeroGrids} />
        )} */}
        {/* <ProjectsIntro data={data.homeHeroGrids} /> */}
        {/* <HeroSlider slides={homeSlides} /> */}
      </div>
      <SingleTitleSection />
      {/* <SlabCollection data={data.slabs} /> */}
      <div className={styles.storyDelimiter}></div>
      <OurStory
        title={data.homePage.title}
        imgSrc={storyMediaUrl}
        text={data.homePage.text}
        mimeType={mimeType}
      />
      <TilesSection />
      <Reviews data={data.reviews} />
      {taleVideoUrl && (
        <VideoSection
          videoUrl={taleVideoUrl}
          youTube_text={youTube_text}
          placeholderImage={videoAltTextField}
        />
      )}
      {!!token && <FollowUs token={token} />}
    </Layout>
  );
};

interface Props {
  data: {
    strapi: {
      homeHeroGrids: {
        background_color?: string;
        text?: string;
        id?: string;
        sortIndex?: number;
        image?: {
          url?: string;
        };
        link?: string;
      }[];
      homePage: {
        da_vinci_marble_tale_media: {
          url: string;
          alternativeText?: string;
        };
        youTube_link: string;
        youTube_text: string;
        Show_first_slider_links_at_new_tab: boolean;
        text: string;
        title: string;
        instagram_token: string;
        our_story_media: {
          mime: string;
          url: string;
        };
      };
      homeSlides: HomeSlide[];
      slabs: HomeSlab[];
      reviews: Review[];
    };
  };
}

export default IndexPage;
