import React, { FC } from 'react';
import styles from './VideoSection.module.scss';
import ReactPlayer from 'react-player';
import { Link } from 'gatsby';
import ArrowButton from '../../shared/arrow-button/ArrowButton';

interface Props {
  // youTube_link: string;
  youTube_text: string;
  videoUrl: string;
  placeholderImage?: string;
}

const VideoSection: FC<Props> = ({ videoUrl, youTube_text, placeholderImage }: Props) => {
  return (
    <>
      <section className={styles.post}>
        <div className={styles.borderedContainer}>
          <h3 className={styles.header}>DA VINCI MARBLE TALE</h3>

          <div className={styles.video}>
            <ReactPlayer
              url={videoUrl}
              width="100%"
              height="100%"
              controls={true}
              muted
              volume={0.5}
              light={placeholderImage}
              playing={false}
            />
          </div>

          <div className={styles.filledContainer}>
            <h3 className={styles.title}>{'Join us on a\n buying trip\n to Italy'}</h3>
          </div>
          <div className={styles.contentBox}>
            <p className={styles.content}>{youTube_text}</p>
            <Link to="/schedule-visit" className={styles.linkTitle}>
              <ArrowButton isWhite={false} name="LET'S TALK" imgLeftMargin={'3rem'} />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoSection;
